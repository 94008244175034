/* eslint-disable import/export */
import { get } from 'lodash';
import { defaultFilters as getCommonFilters } from './common';

export * from './common';

export function defaultFilters(user) {
  const commonFilters = getCommonFilters();
  const companyId = get(user, 'company.id');

  return {
    ...commonFilters,
    companies: `${commonFilters.companies} AND NOT id:${companyId}`,
    experts: get(user, 'administrator')
      ? `${commonFilters.experts} AND NOT id:${companyId}`
      : `${
          commonFilters.experts
        } AND is_registered: true AND NOT id:${companyId}`,
    clients: `${commonFilters.clients} AND NOT id:${companyId}`,
  };
}

import { formatCompany as format } from '@/selectors/postSignup/common';
import { formatTags } from '@/components/Tags/helpers/handler';
import { get, first } from 'lodash';

export const formatCompany = (
  {
    company_contract_types,
    company_personal_skills,
    company_hourly_rate,
    bar_certificate,
    ...otherValues
  },
  kind
) => ({
  ...format({ ...otherValues, company_contract_types, kind }),
  company_personal_skills: company_personal_skills
    ? formatTags(company_personal_skills)
    : [],
  bar_certificate_id: get(first(bar_certificate), 'id'),
  company_hourly_rate: parseInt(company_hourly_rate, 10),
});

import { EXPERT, MIXED } from '@/constants/userKind';
import { FILTER_LIST_EXPERT } from '@/components/Header/SearchBar/constants/lists';

export const getFilteredLists = (list, filterList) => [
  filterList(
    list,
    ({ leader_kind }) => leader_kind === EXPERT || leader_kind === MIXED,
    FILTER_LIST_EXPERT
  ),
];

export { buildSuggestionsList } from './common.suggestions';
export { suggestionFilters } from './common.suggestions';

import { get, first } from 'lodash';
import findLabelByValue from '@/helpers/findLabelByValue';
import { getCompanyTypes } from '@/helpers/company';
import { formatDate } from '@/helpers/date';
import { ASAP, PRECISE } from '@/constants/availabilities';
import initialValues from '@/scenes/Profile/components/modals/ModalitiesModal/initialValues';

export const isCompanySolo = () => true;

export const isAvailable = (availability, availableAt) =>
  availability === ASAP || availableAt === formatDate(new Date());

export const getCompanyType = (company) =>
  findLabelByValue(getCompanyTypes(), company.kind);

// WARNING : CES and KICKLOX only
// If your tenant does not need salary, daily, or tjr, please use an override
const mergeInitialValues = (values) => {
  if (!initialValues) return {};
  const salary = get(values, 'salary', null);
  const daily_rate = get(values, 'daily_rate', null);
  return {
    salary: salary === null ? get(initialValues, 'salary', []) : salary,
    daily_rate:
      daily_rate === null ? get(initialValues, 'daily_rate', []) : daily_rate,
  };
};

export const getEditableModalities = (modalities) => ({
  ...modalities,
  linkedin_vanity_name: get(modalities.leader, 'linkedin_vanity_name'),
  job_name: get(modalities, 'job.name'),
  location_place: modalities.location,
  work_days: [modalities.work_days || 5],
  contract_types: first(modalities.contract_types),
  available_at:
    modalities.availability === PRECISE
      ? new Date(modalities.available_at)
      : null,
  ...mergeInitialValues(modalities, initialValues),
});

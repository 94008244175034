import React from 'react';
import { func } from 'prop-types';
import { RfHelper, RfSelect, RfText } from '@/containers/ReduxForm';
import RequiredField from '@/containers/ReduxForm/RequiredField';
import FormSection from '@/components/FormSection';
import { getPracticeCulture } from '@/helpers/company';
import { translateArrayLabel } from '@/helpers/i18n';
import { lengthSelect, presence } from '@/helpers/validation';
import { EXPERT, CLIENT, MIXED } from '@/constants/userKind';
import { get } from 'lodash';
import { Field } from 'redux-form';

const PracticeCultureSection = ({ t, currentUser }) => {
  const kind = get(currentUser, 'kind', CLIENT);

  function formatOptions() {
    const options = { selectable: true };
    const practiceCultureChoices = [...getPracticeCulture(options)];

    return translateArrayLabel(t, practiceCultureChoices);
  }

  return (
    <RfHelper values={['practice_culture']}>
      {({ practice_culture }) => (
        <FormSection title={t('postSignup.practice_culture.title')}>
          <div className="grid m-b-m">
            <div className="grid__item one-whole">
              <RequiredField
                component={RfSelect}
                searchable={false}
                label={t('postSignup.practice_culture.label')}
                placeholder={t('postSignup.practice_culture.placeholder')}
                className="m-b-s"
                name="practice_culture"
                options={formatOptions()}
                validate={[lengthSelect({ min: 1, max: 2 })]}
                id="practiceCulture"
                data-testid="practiceCulture"
                value={practice_culture}
                multi
              />
            </div>
          </div>
          <div className="grid m-b-m">
            <div className="grid__item one-whole">
              <Field
                name="name"
                label={t('company.modal.modalities.company.name.label')}
                className="m-b-s"
                component={RfText}
                disabled={[MIXED, EXPERT].includes(kind)}
                required
                validate={[presence()]}
                autoComplete="organization"
              />
            </div>
          </div>
        </FormSection>
      )}
    </RfHelper>
  );
};

PracticeCultureSection.propTypes = {
  t: func.isRequired,
  currentUser: func.isRequired,
};

export default PracticeCultureSection;

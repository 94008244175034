import React from 'react';
import { func } from 'prop-types';
import config from '@/_config';
import goals from '@/constants/fields/goals';
import { RequiredField, RfChoices, RfHelper } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import LabelledRadio from '@/components/Choice/LabelledRadio';
import { isSolo } from '@/helpers/company';
import { MISSIONS } from '@/constants/contractTypes';
import { get } from 'lodash';

function ContractTypeSection({ t }) {
  function isContractTypeOptionDisabled(contractTypes, companyType) {
    return contractTypes === MISSIONS && !isSolo(companyType);
  }
  return (
    <RfHelper values={['contract_types', 'kind']}>
      {({ contract_types, kind }) => (
        <FormSection
          title={t('company.modal.modalities.goals.title', {
            companyName: config.displayName,
          })}
        >
          <RequiredField
            type="radioGroup"
            name="contract_types"
            component={RfChoices}
            checkbox={LabelledRadio}
            label={t('company.modal.modalities.contractTypes.label')}
            cbProps={goals
              .filter((goal) => get(goal, 'choiceVisibility', true) === true)
              .map((goal) => ({
                ...goal,
                label: t(goal.label),
                icon: goal.icon,
                disabled: isContractTypeOptionDisabled(contract_types, kind),
              }))}
            withSeparators={false}
          />
        </FormSection>
      )}
    </RfHelper>
  );
}

ContractTypeSection.propTypes = {
  t: func.isRequired,
};

export default ContractTypeSection;

/* eslint-disable import/export */
import { first } from 'lodash';
import { MISSIONS } from '@/constants/contractTypes';
import { formatMission as format } from '@/selectors/missionsCreator/common';

export * from './common';

export const formatMission = ({
  contract_type,
  hourly_rate,
  budget,
  salary,
  daily_rate,
  work_hours,
  ...otherValues
}) => ({
  ...format({ ...otherValues, contract_type }),
  contract_type: MISSIONS,
  contract_types: [MISSIONS],
  hourly_rate: parseInt(hourly_rate, 10) || null,
  work_hours: parseInt(work_hours, 10) || first(work_hours),
  budget,
  salary: null,
  daily_rate,
  salary_confidentiality: false,
});

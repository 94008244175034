import React, { useContext } from 'react';
import { string } from 'prop-types';
import { propType } from 'graphql-anywhere';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { Index, Configure } from 'react-instantsearch-dom';
import Algolia from '@/containers/Algolia';
import userFragment from '@/api/User/fragment';
import { indexes, defaultFilters } from '@/constants/algolia';
import { BREAKPOINTS } from '@/constants/screen';
import { getHomeRoute } from '@/helpers/router';
import Logo from '@/images/logo-small.png';
import SearchBarMixed from './SearchBarMixed';
import DesktopNav from './Nav/Desktop';
import MobileNav from './Nav/Mobile';
import config from '@/_config';
import getSearchQueryURICreate from './SearchBar/helpers/getSearchQueryURICreate';
import { CLIENT } from '@/constants/userKind';
import useTranslation from '@/hooks/useTranslation';
import AdminBanner from '@/components/AdminBanner';
import User from '@/models/User';
import AbilityContext from '@/permissions/contexts/AbilityContext';
import { LANDING } from '@/constants/router';
import './styles.scss';

// tenant specific
function Header({ currentUser, className }) {
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);
  const user = new User(currentUser);

  const filters = defaultFilters(user);

  // If user is logged then use his kind, else use client kind
  const userKind = user.get('kind', CLIENT);

  // If an admin is connected as a user
  const signedInAsUser = user.get('signed_in_as_user', false);
  const accessSearch = ability.can('access_search', user);

  return (
    <MediaQuery maxWidth={BREAKPOINTS.laptop}>
      {(mobile) => (
        <>
          {signedInAsUser && <AdminBanner email={user.get('email')} />}
          <header
            className={`Header ${
              signedInAsUser ? 'Header--withAdminBanner' : ''
            }`}
          >
            <Algolia
              hitsPerPage={accessSearch ? 3 : 0}
              indexName={indexes.tags}
            >
              <>
                {config.search.showCompanies(userKind) &&
                  accessSearch && (
                    <Index indexName={indexes.companies}>
                      {config.search.showMixed(userKind) && (
                        <Configure
                          filters={filters.companies}
                          hitsPerPage={3}
                        />
                      )}
                    </Index>
                  )}
                {config.search.showMissions(userKind) && (
                  <Index indexName={indexes.missions}>
                    <Configure filters={filters.missions} hitsPerPage={3} />
                  </Index>
                )}
              </>

              <div className="d-f ai-c flex-auto">
                {currentUser ? (
                  <Link to={getHomeRoute()} className="m-l-m">
                    <img src={Logo} alt="Logo" className="Header__logo" />
                  </Link>
                ) : (
                  <a href={LANDING} className={className}>
                    <img src={Logo} alt="Logo" className="Header__logo m-l-m" />
                  </a>
                )}
                {!mobile &&
                  accessSearch && (
                    <SearchBarMixed
                      currentUser={currentUser}
                      getSearchQueryURI={getSearchQueryURICreate(userKind)}
                      className="m-l-s"
                    />
                  )}
              </div>
              <div className="d-f jc-c ai-c m-r-s">
                {mobile && (
                  <MobileNav
                    t={t}
                    currentUser={currentUser}
                    searchBarVisible={accessSearch}
                  />
                )}
                {!mobile && <DesktopNav t={t} currentUser={currentUser} />}
              </div>
            </Algolia>
          </header>
        </>
      )}
    </MediaQuery>
  );
}

Header.propTypes = {
  currentUser: propType(userFragment),
  className: string,
};

Header.defaultProps = {
  currentUser: null,
  className: null,
};

export default Header;

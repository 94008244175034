/*
/!\ PLEASE DO NOT USE THE DEFAULT EXPORT OF THIS FILE TO GET COMPANY TYPES
    INSTEAD USE HELPER getCompanyTypes() from @/helper/company.js
*/
import { FREELANCE } from '@/constants/companyKind';
import { CLIENT, EXPERT } from '@/constants/userKind';

export const freelance = {
  label: 'fields.companyKinds.freelance',
  value: FREELANCE,
  id: FREELANCE,
  selectable: false,
  solo: true,
};

export const lawFirm = {
  label: 'fields.companyKinds.law_firm',
  value: 'law_firm',
  id: 'law_firm',
  selectable: true,
  solo: false,
};

const companyKinds = {
  [EXPERT]: {
    freelance,
  },
  [CLIENT]: {
    lawFirm,
  },
};

export default companyKinds;

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import HourlyRateSection from './HourlyRateSection';

export default compose(
  connect((state) => ({
    formValues: getFormValues('company_modalities')(state),
  }))
)(HourlyRateSection);

export default {
  company_level: [0, 30],
  work_days: [5],
  billings: null,
  end_months: [6],
  attachments: [],
  nda_attachment: [],
  category_ids: [],
  sub_categories: [],
  skills: [],
  optional_skills: [],
  daily_rate: null,
  budget: null,
  salary: null,
  hourly_rate: null,
  request_for_quotation: null,
};

import {
  FILTER_LIST_EXPERT,
  FILTER_LIST_MISSION,
  FILTER_LIST_TAG,
} from '@/components/Header/SearchBar/constants/lists';
import {
  getExpertRoute,
  getExpertsRoute,
  getMissionRoute,
  getMissionsRoute,
} from '@/helpers/router';

export const buildSuggestionsList = (list, hits, addLinkToHit) => ({
  [FILTER_LIST_TAG]: {
    ...list,
    sectionName: 'search.list.suggestions.title',
    hits,
  },
  [FILTER_LIST_MISSION]: {
    ...list,
    sectionLink: getMissionsRoute(),
    sectionName: 'search.list.filtered.title',
    hits: hits.map(addLinkToHit(getMissionRoute)),
  },
  [FILTER_LIST_EXPERT]: {
    ...list,
    sectionLink: getExpertsRoute(),
    sectionName: 'search.list.filtered.title',
    hits: hits.map(addLinkToHit(getExpertRoute)),
  },
});

export const suggestionFilters = [
  {
    key: 'search.suggestions.filter.mission',
    index: FILTER_LIST_MISSION,
    path: 'missions',
    isDefault: true,
  },
  {
    key: 'search.suggestions.filter.company',
    index: FILTER_LIST_EXPERT,
    path: 'experts',
  },
];

import config from '@/_config';
import { FREELANCE } from '@/constants/companyKind';

export default {
  goals: [],
  work_days: [5],
  salary: [config.company.salary.min, config.company.salary.max],
  daily_rate: [config.company.dailyRate.min, config.company.dailyRate.max],
  kind: FREELANCE,
  practice_culture: [],
};

import { userAbility } from './user.common';

export default function defineAbilityForUser(can) {
  userAbility(can);
  can(
    ['display_message_on_proposal_view', 'access_proposal_creation'],
    'User',
    (user) =>
      user.isMissionOrFulltimeContractType() ||
      user.isMissionContractType() ||
      user.isFulltimeContractType()
  );
}

export default function defineAbilityForMission(can, user, config) {
  can(
    'candidate_to',
    'Mission',
    (mission) =>
      !mission.isArchived() &&
      !user.isMissionOwnedByCurrentCompany(mission) &&
      (user.isExpert() || user.isMixed()) &&
      (config.mission.relevanceScore.show
        ? user.isRelevanceMaxScoreReached()
        : true) &&
      !mission.shouldSignNda() &&
      !mission.limitDateHasPassed() &&
      mission.isPublished()
  );

  can('create_a_proposal_for', 'Mission', (mission) => {
    if (config.global.showProposals) {
      return (
        mission.hasCandidatedWithoutProposal() &&
        !mission.isArchived() &&
        mission.isPublished() &&
        !user.hasArchivedCandidacyForMission(mission)
      );
    }
    return false;
  });

  can('see_proposal', 'Mission', (mission) => mission.hasProposal());

  can(
    'see_conversation',
    'Mission',
    (mission) =>
      mission.hasCandidated() && !user.hasArchivedCandidacyForMission(mission)
  );

  can(
    'see_remuneration',
    'Mission',
    (mission) =>
      !(
        !user.isMissionOwnedByCurrentCompany(mission) &&
        mission.hasSalaryConfidentiality()
      )
  );

  can(
    'blacklist',
    'Mission',
    (mission) =>
      !user.isClient() &&
      !user.isMissionOwnedByCurrentCompany(mission) &&
      !mission.hasBlacklisted() &&
      mission.isPublished()
  );

  can(
    'invite',
    'Mission',
    (mission) => !mission.hasBlacklisted() && !mission.hasCandidated()
  );

  can(
    'see_invitation',
    'Mission',
    (mission) =>
      !(
        mission.hasBlacklisted() ||
        mission.hasCandidated() ||
        mission.isArchived()
      )
  );

  can(
    'edit_vip',
    'Mission',
    (mission) => mission.isDraft() || mission.isInReview()
  );

  can('see_proposal_button', 'Mission', (mission) => {
    if (config.global.showProposals) {
      return (
        mission.isMissionOrFulltime() ||
        mission.isMission() ||
        mission.isFulltime()
      );
    }
    return false;
  });
}

import React from 'react';
import { objectOf, any } from 'prop-types';
import { get } from 'lodash';
import useTranslation from '@/hooks/useTranslation';
import HourlyRate from '@/components/HourlyRate';
import FormSection from '@/components/FormSection';

const HourlyRateSection = ({ currentUser, formValues }) => {
  const expertContracts = get(currentUser, 'company.expert_contracts');
  const { t } = useTranslation();
  const currentFormValues = {
    expTime: get(formValues, 'exp_time'),
    categoryIds: get(formValues, 'categories', []).map((c) => get(c, 'id')),
    practiceCulture: get(formValues, 'practice_culture'),
    locationRegion: get(formValues, 'location_place.province', ''),
    hourlyRate: get(formValues, 'hourly_rate'),
    contractsCount: get(expertContracts, 'count'),
  };

  return (
    <FormSection title={t('company.modal.modalities.remuneration.title')}>
      <HourlyRate simulatorParams={currentFormValues} name="hourly_rate" />
    </FormSection>
  );
};

HourlyRateSection.propTypes = {
  formValues: objectOf(any).isRequired,
  currentUser: objectOf(any).isRequired,
};

export default HourlyRateSection;

import { isExpert, isMixed, isClient } from '@/selectors/user';
import JobSection from './JobSection';
import ContractTypeSection from './ContractTypeSection';
import PracticeCultureSection from './PracticeCultureSection';
import ExperienceSection from './ExperienceSection';
import HourlyRateSection from './HourlyRateSection';
import ModalitiesSection from './ModalitiesSection';
import WorkRateSection from './WorkRateSection';
import AvailabilitySection from './AvailabilitySection';
import SearchStatusSection from './SearchStatusSection';
import SizeSection from './SizeSection';

export default ({ leader }) => {
  const isLeaderExpert = isExpert(leader) || isMixed(leader);
  const isLeaderClient = isClient(leader) || isMixed(leader);
  return [
    isLeaderExpert && { id: 'searchStatus', component: SearchStatusSection },
    isLeaderExpert && { id: 'availability', component: AvailabilitySection },
    isLeaderExpert && { id: 'contractType', component: ContractTypeSection },
    { id: 'practiceCulture', component: PracticeCultureSection },
    { id: 'job', component: JobSection },
    isLeaderExpert && { id: 'experience', component: ExperienceSection },
    isLeaderExpert && { id: 'hourly_rate', component: HourlyRateSection },
    { id: 'modalities', component: ModalitiesSection },
    isLeaderExpert && { id: 'workRate', component: WorkRateSection },
    isLeaderClient && { id: 'size', component: SizeSection },
  ].filter((s) => s);
};

import { stubFalse } from 'lodash';
import { isTokenExpired } from '@/helpers/auth';
import { api, authPrefix, tenant, name } from '@/constants/environment';
import { TECHNICAL_ASSISTANCE } from '@/constants/quotationKind';
import { CLIENT, EXPERT, MIXED } from '@/constants/userKind';
import { FR } from '@/constants/locales';
import { MY_MISSIONS, MISSIONS_MATCHING } from '@/constants/router_base';
import { POST_SIGNUP, PROFILE } from '@/constants/features';
import { FREELANCE, RECRUITMENT_CANDIDATE } from '@/constants/companyKind';
import { HOUR } from '@/constants/costPerKind';
import {
  Review,
  Published,
  Archived,
} from '@/constants/fields/missionStatus_default';
import {
  FORTY_FIVE_DAYS,
  THIRTY_DAYS,
  FIFTEEN_DAYS,
  IMMEDIATE,
} from '@/constants/PaymentPeriod';
import {
  ARCHIVE_REASON_CANCELLED,
  ARCHIVE_REASON_WON,
} from './constants/mission/archiveReasons';
import {
  MY_APPLICATIONS_LINK,
  MY_MISSIONS_LINK,
  PROPOSALS_LINK,
  CONTRACTS_LINK,
  INVOICES_LINK,
  MY_COMMUNITY,
} from './constants/Header/navItems';
import { END } from '@/constants/positions';
import { SECONDARY } from '@/constants/cssButtonVariant';

export default {
  name: tenant,
  displayName: name,
  lang: [FR],
  api,
  authPrefix,
  registerPrefix: tenant,
  algoliaPrefix: tenant,
  herokuPrefix: tenant,
  homePath: {
    client: MY_MISSIONS,
    expert: MISSIONS_MATCHING,
    mixed: MISSIONS_MATCHING,
  },
  global: {
    openOffer: true,
    linkedinLogin: true,
    ssoLogin: false,
    resumeSync: true,
    hasMixedUsers: true,
    signUpMixed: false,
    showBookmarks: (userKind) =>
      !userKind || userKind === CLIENT || userKind === MIXED,
    showClientPool: () => false,
    showProfile: () => true,
    showNda: (userKind) => userKind === EXPERT || userKind === MIXED,
    showClientPools: () => false,
    showProposals: true,
    showRelevance: true,
    authView: true,
    defaultCountry: 'FR',
    tawkTo: false,
    conditionalCategories: false,
    viralLoops: false,
    maxSizeAttachment: 25000000,
    expertSearchAccess: true,
    showCaseBarOffline: false,
    barRegistration: true,
    landingLinkInvitationsBanner: {
      enable: false,
      link: '#',
    },
    // TODO: set to true when deeplink is ready
    showAssistance: false,
    showBilling: true,
    showTrainings: () => false,
  },
  header: {
    expert: [
      MY_APPLICATIONS_LINK,
      PROPOSALS_LINK,
      CONTRACTS_LINK,
      INVOICES_LINK,
      MY_COMMUNITY,
    ],
    client: [MY_MISSIONS_LINK, CONTRACTS_LINK, INVOICES_LINK, MY_COMMUNITY],
    mixed: [
      MY_APPLICATIONS_LINK,
      MY_MISSIONS_LINK,
      PROPOSALS_LINK,
      CONTRACTS_LINK,
      INVOICES_LINK,
      MY_COMMUNITY,
    ],
    partner: null,
  },
  contract: {
    disabled: false,
    // Same tabs in ContractsTab and InvoicesTabs
    tabs: [
      {
        id: 'Swimee',
        value: EXPERT,
        label: 'contract.expert',
      },
      {
        id: 'Swimer',
        value: CLIENT,
        label: 'contract.client',
      },
    ],
  },
  conversation: {
    // handle the section which display information about relevance score.
    // Ex: Used to inform why an expert can not use conversation feature
    relevanceScore: {
      show: true, // show or not RelevanceScoreInformation
      icon: true, // show or not icon on RelevanceScoreInformationModal
      maxScore: 2, // step to achieve to unlock feature, for kicklox, 2 === Giga
    },
    actionButtonColor: SECONDARY,
    noteColor: SECONDARY,
  },
  search: {
    showExperts: (userKind) =>
      !userKind || userKind === CLIENT || userKind === MIXED,
    showClients: stubFalse,
    showMixed: stubFalse,
    showCompanies: (userKind) =>
      !userKind || userKind === CLIENT || userKind === MIXED,
    showMissions: (userKind) => userKind === EXPERT || userKind === MIXED,
    restrictedAccess: true,
    restrictSearchableAttributes: (userKind) => userKind === CLIENT,
    tooMuchHitsWording: false,
    showAllResultsButton: (userKind) => userKind !== MIXED,
  },
  matching: {
    showSkills: true,
    showJob: true,
    showRemuneration: true,
    showLocation: true,
    showSectors: true,
  },
  login: {
    showHeadline: true,
  },
  kanban: {
    showMultipleRecruiters: false,
    showRecommendedBy: true,
    lockForReviewMission: true,
    customInformationMessage: true,
    showMissionContext: true,
  },
  postSignup: {
    hasAsideDescription: true,
    showPersonalSkills: true,
    requiredPersonalSkills: true,
  },
  postSignupCvParsing: {
    showAtThe: END,
    hasAsideDescription: true,
  },
  settings: {
    showAvatar: () => true,
    showName: () => true,
    showEmail: () => true,
    showPhone: () => true,
    showJob: () => true,
    userJobRequired: true,
    userPhoneRequired: true,
    showEmailNotification: true,
    showContractNotification: true,
    showInvoiceNotification: true,
    showLinkedinUrl: true,
    showMembers: true,
    showDocuments: true,
    showCompany: true,
    clientDetailsSection: false,
    showN_1Revenues: false,
    requiredRCS: false,
    requiredNAF_APE: false,
    showRCS: true,
    showNAF_APE: true,
    requiredSIRET: true,
    barRegistrationSection: true,
    showSIRET: false,
    requiredDescription: false,
    requiredCompanyCreated: false,
    showSirenSection: false,
    showSirenWarningMessage: false,
    showActivityNotifications: true,
    showConversations: true,
    showClientPool: false,
    showLanguages: false,
  },
  mission: {
    showCompanyLogo: true,
    showAbout: true,
    showApply: (userKind) => userKind === EXPERT || userKind === MIXED,
    showApplyMixed: (userKind) => userKind === MIXED,
    showContext: true,
    showOwnership: () => false,
    // handle the section which display information about relevance score.
    // Ex: Used to inform why an expert can not candidate to a mission
    relevanceScore: {
      show: true,
      maxScore: 2, // step to achieve to unlock feature, for kicklox, 2 === Giga
    },
    showDomains: false,
    showNdaSection: false,
    showCompanyConfidentialitySection: true,
    experienceTime: { min: 0, max: 30 },
    nameLimitedList: true,
    showOutsourcing: true,
    applyWithAttachment: true,
    applyWithSalary: true,
    apply: {
      candidateWithAttachment: true,
    },
    partnerOffer: false,
    openOffline: false,
    showDescription: false,
    showEngineerSection: false,
    requiredLanguages: false,
    hourlyRate: { min: 70, max: 2500 },
    showHourlyRate: true,
    requiredCategories: true,
    showMissionSalaryConfidentiality: true,
    showOthersModalities: false,
    customInformationModal: true,
    tabsStatuses: [Published, Review, Archived],
    archiveReasons: () => [ARCHIVE_REASON_WON, ARCHIVE_REASON_CANCELLED], // radio list of reason of archiving in ArchiveMissionModal.js
    startDateConditionedByContractType: false,
    endDateConditionedByContractType: false,
    contextConditionedByContractType: false,
    companyLevelConditionedByContractType: false,
    locationPlaceConditionedByContractType: false,
    modalitiesConditionedByContractType: false,
    locationPlaceWithDetails: true,
    showCompanySection: true,
    redirectCandidacy: false,
    showDisclaimer: true,
    showMatchingScore: false,
    showConsultation: false,
    card: {
      showSubTitleSubJobLocation: true,
      showBillingModality: true,
      showTotalDutyModality: true,
      showStartDateModality: true,
      showLocationModality: false,
      showCandidaciesInKanbanForExpert: true,
    },
    skills: {
      withFavorite: false,
      optionalSkills: {
        minLength: null,
        required: false,
      },
      personalSkills: {
        show: false,
      },
    },
    showDeliverables: true,
    showContextField: true,
    showKindSection: true,
    showRecruitment: true,
    showContractTypes: false,
    showBillingMethod: true,
    showTools: false,
  },
  company: {
    showKind: false,
    showExperience: (kind) => [EXPERT, MIXED].includes(kind),
    showCategories: (companyKind) => [EXPERT, MIXED].includes(companyKind),
    showSkills: true,
    showReview: false, // TODO change for true when feature will be ready
    showCertifications: true,
    showEquipments: false,
    showExperiences: true,
    showDegrees: true,
    showLanguages: true,
    showPlatformRecommendation: true,
    showRecommendationProfileButton: true, // TODO wil be removed when feature will be ready
    showMembersButton: false, // TODO wil be removed when feature will be ready
    showEntities: false,
    showManager: false,
    showCompetenciesFile: true,
    showCandidacy: true,
    canDownloadPdf: true,
    canDownloadDocx: true,
    showBookmarks: (companyKind) =>
      companyKind === EXPERT || companyKind === MIXED,
    showCollaborate: true,
    showConverse: false,
    showExpertises: false,
    showIndividualCertificationSkills: false,
    showKeywords: false,
    showAdminCertificationStatus: true,
    // Extended Cv upload feature allow user to choose if he wants to display his
    // cv on his profile or not. It also allow user to choose if the cv parsing should override
    // his own profile infos
    extendedCvUpload: false,
    updateProfileCvUpload: true,
    showCompanySalaryConfidentiality: false,
    showAdminSection: true,
    dailyRate: { min: 150, max: 2000 },
    budgetRate: { min: 0, max: 20000 },
    salary: { min: 20000, max: 150000, step: 5000 },
    experienceTime: { min: 0, max: 30 },
    experienceTimeRestriction: (companyKind) =>
      [FREELANCE, RECRUITMENT_CANDIDATE].includes(companyKind),
    showLinkedinField: true,
    jobNameLimitedList: true,
    companyJobNameLimitedList: true,
    certificationEntitledLimitedList: false,
    showLegalInfoInAdminSection: false,
    showCollaborators: false,
    expertCompanyKindIsSolo: true,
    showPracticeCulture: true,
    contractTypeIsAlwaysMissions: true,
    hourlyRate: { min: 70, max: 2500 },
    showHourlyRate: true,
    showFinishedMissions: true,
    showExpTimeForMixed: true,
    showClientActions: false,
    showInterviewsConduct: false,
    showJoinUs: false,
    showCompanyValues: false,
    showSocialNetworks: false,
    showCategoriesAboveSectors: true,
    tools: {
      expert: { show: false },
      client: { show: false },
    },
    maxRelevanceScore: 3,
    showRewards: false,
  },
  sectors: {
    sorting: true,
  },
  // Configuration for Skills (@/components/Skills)
  skills: {
    // Configuration for @/components/Skills/components/SkillRating
    rating: {
      activeFeatures: [POST_SIGNUP, PROFILE], // features which will use SkillRating
      max: 4,
    },
    // Configuration for @/components/Skills/components/SkillsView
    highlightMax: 4, // corresponds to the number of the highlighted skills ( = weight more important)
    limitedList: true, // limited list skills to select (in profile, project, experience, degree, certification, mission, etc...)
    // TO REMOVE !
    // swim uses optional skill as personnal
    // to not create more confusion, optional
    // skills must be replace by personnal skills in mission form
    optionalSkillsIsPersonalSkills: true,
    kindRequestTagPersonalSkill: 'skill',
    withRatingLetters: false,
    name: {
      maxLength: 45,
      invalidCharactersRegex: /[•\\(),;«»"°_@=%€$£*§|]/,
    },
  },
  equipments: {
    limitedList: false,
  },
  proposal: {
    showQuote: {
      billingChoice: true,
    },
    defaultBilling: TECHNICAL_ASSISTANCE,
    displayPaymentPeriod: true,
    displayIndemnity: false,
    professionalIndemnity: false,
    workDays: { min: 1, max: 7, step: 1 },
    workHours: { min: 1, max: 14, step: 1 },
    costPer: HOUR,
    displayDiscountEqualZero: false,
    paymentPeriodChoices: {
      [IMMEDIATE]: {
        coefficient: 0,
      },
      [FIFTEEN_DAYS]: {
        coefficient: 0,
      },
      [THIRTY_DAYS]: {
        coefficient: 0,
      },
      [FORTY_FIVE_DAYS]: {
        coefficient: 0,
      },
    },
  },
  milestone: {
    hoursWorked: { min: 0, max: 10000, step: 1 },
  },
  notifications: {
    displayMissionName: false,
  },
  invoice: {
    proformaActivated: false,
  },
  routes: {
    home: { authenticated: false },
    signup: {
      restricted: false,
      clientPhoneField: true,
      expertPhoneField: false,
      footer: false,
      requiredCompanyNameField: true,
      displayCompanyNameField: true,
    },
    login: {},
    forgotPassword: {},
    resetPassword: {},
    confirmEmail: { enable: true },
    resendEmailConfirmation: { enable: true },
    logout: {},
    postSignup: {},
    missionCreator: {},
    missions: {
      authenticated: false,
      footer: () => isTokenExpired(),
    },
    mission: {},
    proposalCreator: {},
    proposals: {},
    proposal: {},
    experts: {
      authenticated: true,
      footer: () => isTokenExpired(),
    },
    expert: {},
    clientPool: { restricted: CLIENT },
    clientPools: { restricted: true },
    clients: { restricted: true },
    client: { restricted: true },
    profile: {},
    bookmarks: { restricted: CLIENT },
    bookmark: { restricted: CLIENT },
    settings: {},
    admin: {},
    myMissions: {},
    myMissionBoard: {},
    myMissionProposals: {},
    myMissionMatches: {},
    myMissionEditor: {},
    myApplications: {},
    conversations: {},
    contracts: { restricted: false },
    invoices: {
      restricted: false,
      bannerMessage: false,
    },
    contract: { restricted: false },
    nda: {},
    community: {},
  },
  validation: {
    mission: {
      minSkillsLength: 1,
      maxSkillsLength: 4,
      maxSectorsLength: null,
      maxSubCategoriesLength: null,
      maxCategoriesLength: 2,
      minIndividualCertificationSkillsLength: 1,
      minFavoriteLength: 1,
    },
    expertProfile: {
      minSkillsLength: 1,
      maxOptionalSkillsLength: 4,
      minOptionalSkillsLength: 1,
      maxSectorsLength: null,
      maxSubCategoriesLength: 10,
      maxCategoriesLength: 2,
      minIndividualCertificationSkillsLength: 1,
    },
    general: {
      maxCategoriesWithFavorite: 2,
    },
  },
};

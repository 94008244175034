import {
  LeadersShareholders,
  InvestmentFunds,
  ListedCompanies,
  StartupsAndInnovation,
  DigitalAndEcommerce,
  MediaAndCommunication,
  FranchiseDistributionCommerce,
  Entertainment,
  HospitalityAndLeisureRestaurant,
  BankingInsuranceMutual,
  TextileAndLuxury,
  RealEstateAndDevelopment,
  Industrial,
  Institutional,
  NonProfitOrganizationsAndSocialEconomy,
  EnergyAndEnvironment,
  LiberalProfessions,
  ArtisansAndTradesPeople,
  InstitutionalAndPublicFigures,
  IndividualsAndFamilies,
  MultisectoralOrNoSectorialExpertise,
} from './sectors_default';

export default [
  LeadersShareholders,
  InvestmentFunds,
  ListedCompanies,
  StartupsAndInnovation,
  DigitalAndEcommerce,
  MediaAndCommunication,
  FranchiseDistributionCommerce,
  Entertainment,
  HospitalityAndLeisureRestaurant,
  BankingInsuranceMutual,
  TextileAndLuxury,
  RealEstateAndDevelopment,
  Industrial,
  Institutional,
  NonProfitOrganizationsAndSocialEconomy,
  EnergyAndEnvironment,
  LiberalProfessions,
  ArtisansAndTradesPeople,
  InstitutionalAndPublicFigures,
  IndividualsAndFamilies,
  MultisectoralOrNoSectorialExpertise,
];
